import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Container, Grid, makeStyles } from '@material-ui/core';
import NavigationBar from './Menubar/mainmenu';
import styles from './header.module.scss';
import MemberCardStatusWarningMessage from './MemberCardStatusWarningMessage/MemberCardStatusWarningMessage';
import { useReactiveVar } from '@apollo/client';
import { userInfoVar } from '../apollo/localState/userInfoVar';
import { getWarningMessageByCardStatus } from '../utils/common';
import { accessTokenVar } from '../apollo/localState/accessTokenVar';
import { SystemStatusContext } from '../providers/SystemStatusProvider';
import MediaKit from '../assets/MediaKit';

const useStyles = makeStyles({
  mkdnyLogo: {
    backgroundImage: `url(${MediaKit.header?.logoImageSrc})`,
  },
});

const HeaderWsb = () => {
  const { accessToken } = useReactiveVar(accessTokenVar);
  const { isMemberCardActive, status } = useReactiveVar(userInfoVar);
  const { headerMessage } = useContext(SystemStatusContext);

  const classes = useStyles();

  return (
    <header className={styles.headerContainer}>
      <Container maxWidth="lg">
        <Grid
          container
          component="div"
          className={styles.headerWrapper}
          spacing={0}
          alignItems="center"
        >
          <Grid item component="div" md={3} className={styles.logoSpace}>
            <Link to="/">
              <div className={`${styles.mkdnyLogo} ${classes.mkdnyLogo}`} />
            </Link>
          </Grid>
          <Grid item component="div" md={9} className={styles.menuSpace}>
            <NavigationBar />
          </Grid>
        </Grid>
      </Container>
      {headerMessage && (
        <MemberCardStatusWarningMessage message={headerMessage} />
      )}
      {accessToken && !isMemberCardActive && (
        <MemberCardStatusWarningMessage
          message={getWarningMessageByCardStatus(status)}
        />
      )}
    </header>
  );
};

export default HeaderWsb;
