import { isBefore, isSameDay, add as addDate } from 'date-fns';

export const getWarningMessageByCardStatus = status => {
  const rootMessage = 'Korlátozott hozzáférés. Ok:';
  const messages = {
    Inactive: 'Inaktív tagsági kártya',
    Expired: 'Lejárt tagsági kártya',
    Disabled: 'Letiltott tagsági kártya',
  };
  const messageArray = [rootMessage, messages[status]].filter(x => x);

  return messageArray.join(' ');
};

/**
 * OKPD-10461 - A hosszabbítás gomb legyen aktív a kölcsönzés lejárata előtt 5 nappal
 *
 * @param {Date | string} expiryDate - Loan expiry date
 * @param {number} day - How many day to activate extend button before expiry date
 * @returns {boolean}
 */
export const isExtendButtonDisabledByDate = (expiryDate, day) => {
  const now = new Date();
  const afterFiveDayDate = addDate(now, {
    days: day,
  });

  const date = new Date(expiryDate);

  return isSameDay(afterFiveDayDate, date) || isBefore(afterFiveDayDate, date);
};
