import React from 'react';
import { Link } from 'gatsby';
import { Container, Grid, Box, Breadcrumbs, makeStyles } from '@material-ui/core';
import styles from '../pages/index.module.scss';
import MediaKit from '../assets/MediaKit';
import { pathsToIgnore } from '../utils/featureControl';

const useStyles = makeStyles({
  topContainerSubpage: {
    backgroundImage: `url(${MediaKit.body?.backgroundImageSrc})`,
  },
});

const BreadcrumbsLayout = (props) => {
  const { menuItems = [] } = props;

  const output = [];
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i];
    if (pathsToIgnore.includes(menuItem.path)) {
      continue;
    }

    if (i === menuItems.length - 1) {
      output.push(
        <span className={styles.breadcrumbMenuNow} key={menuItem.name}>
          {menuItem.name}
        </span>
      );
    } else {
      output.push(
        <Link
          to={menuItem.path}
          className={styles.breadcrumbMenuLink}
          key={menuItem.name}
        >
          {menuItem.name}
        </Link>
      );
    }
  }

  const classes = useStyles();

  return (
    <Grid
      className={styles.mainContainer}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Box
        className={`${styles.topContainerSubpage} ${classes.topContainerSubpage}`}
        component="section"
      ></Box>
      <Box
        className={[
          styles.pageContentContainerSubpage,
          styles.pageContentContainerSubpageWhite,
        ].join(' ')}
        component="section"
      >
        <Container maxWidth={'lg'}>
          <Box className={styles.breadcrumbContainerSubpage} component="div">
            <Breadcrumbs separator="/" aria-label="breadcrumb">
              {output}
            </Breadcrumbs>
          </Box>
          {props.children}
        </Container>
      </Box>
    </Grid>
  );
};

export default BreadcrumbsLayout;
