import React from 'react';
//COMPONENETS
import Layout from '../layouts/subpage_layout';
import Head from '../components/head';

//STYLE
import styles from './index.module.scss';

//HOC
import BreadcrumbsLayout from '../layouts/breadcrumbs_layout';

//GRAPHQL
import { graphql, useStaticQuery } from "gatsby"



const NotFound = props => {

    const data = useStaticQuery(graphql`
        query{
        markdownRemark(frontmatter: {title: {eq: "404-es oldal"}}){
            frontmatter{
            title
            mainpage
            }
            html
        }
    }
    `)


    return (
        <Layout>
            <Head title={data.markdownRemark.frontmatter.title} />
            <BreadcrumbsLayout menuItems={[{ name: data.markdownRemark.frontmatter.mainpage, path: '/' }, { name: data.markdownRemark.frontmatter.title }]}>
                <div className={styles.textContentContainer} style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}>
                </div>
            </BreadcrumbsLayout>
        </Layout>
    )
}

export default NotFound;